<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <v-spacer></v-spacer>
        <span class="text-h5">{{this.form.id ? 'Edição' : 'Cadastro'}} de Pacote</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text align="center" justify="center" style="height: 460px; overflow-x: auto;">
        <v-tabs v-model="tab">
          <v-tab key="1">GERAl</v-tab>
          <v-tab key="2">PRODUTOS</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="1">
            <FormInput
              label="Nome"
              v-model="form.nome"
            />
            <v-textarea
              class="mt-2"
              clearable
              clear-icon="mdi-backspace-outline"
              v-model="form.descricao"
              label="Descrição"
              hide-details
              outlined
            />
           <FormInput
            label="Ordem Loja"
            v-model="form.ordem_loja"
            />
            <FormInputMask
              label="Valor Venda"
              prefix="R$"
              placeholder="0.00"
              :mask="['#.##','##.##','###.##','####.##','#####.##']"
              v-model="form.valor_venda"
            />
            <FormInputMask
              label="Valor Promocional"
              prefix="R$"
              placeholder="0.00"
              :mask="['#.##','##.##','###.##','####.##','#####.##']"
              v-model="form.valor_promocional"
            />
            <v-select
              class="mt-2"
              v-model="form.status"
              :items="[
                {value: 'ativo', text: 'Ativo'},
                {value: 'inativo', text: 'Inativo'},
              ]"
              label="Status"
              hide-details
              outlined
              dense
            />
            <v-select
              class="mt-2"
              v-model="form.periodicidade"
              :items="periodicidades"
              label="Periodicidade"
              hide-details
              outlined
              dense
            />
            <FormInput
              label="Link de Instruções"
              v-model="form.link_instrucoes"
            />
            <div class="my-2 image-container">
              <span>Imagem (240px x 150px)</span>
              <ImageInput v-model="form.imagens" />
            </div>
          </v-tab-item>
          <v-tab-item key="2">
            <v-autocomplete
              @change="onSelectProduct"
              v-model="produto"
              :items="produtos"
              append-icon="mdi-magnify"
              class="mt-2"
              label="Selecione os produtos do pacote"
              item-text="nome"
              item-value="id"
              return-object
              single-line
              hide-details
              outlined
            />
            <v-data-table
              fixed-header
              :headers="headers"
              :items="pacoteProdutos"
              sort-by="id"
              :mobileBreakpoint="0"
              height="250"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.quantidade="{ item }">
                <v-select
                  class="mt-2"
                  v-model="item.quantidade"
                  :items="quantidades"
                  label="Quantidade"
                  hide-details
                  outlined
                  dense
                />
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.action="{ item }">
                <v-btn icon color="red" @click="removeProduct(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
          <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
          <v-spacer />
          <v-btn color="success" @click="salvar" :loading="loading">
            <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormInput from '@/components/FormInput';
import ImageInput from '@/components/ImageInput';
import FormInputMask from '@/components/FormInputMask';

export default {
  name: 'PackForm',

  components: {
    FormInput,
    ImageInput,
    FormInputMask,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: Object
    },
  },

  data: () => ({
    loading: false,
    form: {},
    tab: null,
    headers: [
      { text: 'Nome', value: 'nome', sortable: false, },
      { text: 'Qtd', value: 'quantidade', sortable: false, },
      { text: '', value: 'action', sortable: false, align: 'end'},
    ],
    quantidades: [...Array(100).keys()].map(x => x + 1),
    produto: {},
    produtos: [],
    pacoteProdutos: [],
    periodicidades: [
      {value: 'mensal', text: 'Mensal'},
      {value: 'bimestral', text: 'Bimestral'},
      {value: 'trimestral', text: 'Trimestral'},
      {value: 'semestral', text: 'Semestral'},
      {value: 'anual', text: 'Anual'},
    ],
  }),

  async mounted() {
    await this.fillProducts();
    this.form = this.formData;
  },

  watch: {
    formData(value) {
      this.form = value ? JSON.parse(JSON.stringify(value)) : {};
      this.pacoteProdutos = this.form?.produtos || [];
    },
  },

  methods: {
    salvar() {
      const id = this.form.id;
      this.form.produtos = this.pacoteProdutos;

      if (!this.form.produtos.length) {
        this.$toast.error('Informe ao menos um produto');
        return;
      }

      if (id) {
        this.loading = true;
        this.$http.put(`pacotes/${id}`, this.form).then(resp => {
          if (resp.data.type === 'warning') {
            this.$toast.error(resp.data.msg);
            return;
          }

          this.$emit('onCadastro', resp.data.data);
          this.$toast.success('Atualizado com sucesso!');
        }).catch(() => {
          this.$toast.error('Não foi possivel concluir a operação');
        }).finally(() => (this.loading = false));
        return;
      }

      this.loading = true;
      this.$http.post('pacotes', this.form).then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        this.$emit('onCadastro', resp.data.data);
        this.$toast.success('Cadastrado com sucesso!');
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      }).finally(() => (this.loading = false));
    },

    async fillProducts() {
      this.produtos = [];
      await this.$http.get('produtos').then(resp => {
        this.produtos = resp.data.data;
      })
      .catch(() => {
        this.notify('Não foi possivel concluir a operação', 'warning');
      });
    },

    onSelectProduct(produto) {
      const exist = this.pacoteProdutos.find(e => e.nome == produto.nome);

      if (exist) {
        this.$toast.info('Produto já adicionado');
        this.produto = {};
        return;
      }

      const data = JSON.parse(JSON.stringify(produto));
      data.produto_id = data.id;
      data.id = null;

      this.pacoteProdutos.push(data);
      this.produto = {};
    },

    removeProduct(id) {
      if (!confirm('Deseja remover o produto do pacote?')) {
        return;
      }

      this.pacoteProdutos = this.pacoteProdutos.filter(c => c.id != id);
    },
  }
}
</script>

<style scoped>
.v-card {
  padding: 0 15px 0 15px;
}

.image-container {
  border: solid 1px #888;
  border-radius: 5px;
}
</style>
